/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Page from '../components/page';

import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';

import ReactMarkdown from '../components/markdown';

export default function Protections (props) {
    const root = props.match.path;

    return (
      <Page
        articleClass="launch-page"
        {...props}
      >
        <ReactMarkdown
          source={`
## Understanding Consumer Protections
Protect your current and future finances and assets by knowing your rights. Find out about the special consumer credit protections that are in place for members of the military community. These protections include the Military Lending Act and Servicemembers Civil Relief Act, and you can read about them using the resources you’ll find here, including the Consumer Credit Guide for Members of the Armed Forces and articles and graphics. Be sure to stay on top of your credit report, too, and arm yourself with resources, tips and information to help you reach your financial goals.

[Consumer-Credit-and-Protections]()
### Consumer Credit and Protections
As a military member, you have consumer credit protections available to you. Learn about those protections now, take advantage of regular credit monitoring and avoid high-interest credit cards and minimum monthly payments.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ProtectFinancesPCS-DDP.jpg"
            alt="Truck and open moving box with umbrella, contract, globe, checklist, money stack"
            title="Protect Your Finances During PCS"
            href={`${root}/ProtectFinancesPCS`}
            link="Read Now"
          >
            <p>
            It can be challenging to keep on top of your finances during a PCS. Our checklist will help you stay on track.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-MCM-ShopSmartSavvyBuying-I.jpg"
            alt="Shop Smart: Savvy Buying Tips for Military Families infographic"
            title="Savvy Buying Tips for Military Families"
            href="/assets/downloads/FINRED-MCM-ShopSmartSavvyBuying-I.pdf"
            link="Learn More"
          >
            <p>
            Make the most of your money as a military family. Use this infographic to bag savings at your commissary and exchange.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PricingStrategies-FS.jpg"
            alt="Stop Before You Shop! Don't Buy Into These “Spend More” Pricing Strategies Fact Sheet"
            title="Stop Before You Shop! Don't Buy Into These “Spend More” Pricing Strategies"
            href="/assets/downloads/FINRED-PricingStrategies-FS.pdf"
            link="Download"
          >
            <p>
            This fact sheet outlines methods marketers use to get military families to spend more money and what buyers should be aware of before shopping either in person or online.
            </p>
          </FeaturedAsset>
        </div>


        <ReactMarkdown
          source={`
* [Consumer Credit](${root}/ConsumerCredit)
Learn about the basics of credit — including simple ways to boost your financial health and protect your information — in this eight-part video series.
* [Consumer Credit Guide for Members of the Armed Forces](https://issuu.com/dodfinred/docs/finred-mlaconsumercredit-bk?e=0)
The Consumer Credit Guide for Members of the Armed Forces is your go-to source for special protections for service members and families, resources and more.
* [Dealing With Debt Collectors: Know Your Rights](/assets/downloads/FINRED-MCM-DealingwithDebtCollectors-I.pdf)
The Fair Debt Collection Practices Act prohibits debt collectors from using abusive, unfair or deceptive practices to collect certain debts. Know the rights this gives you as a consumer.
* [Ending Your Lease Early With Military Orders: Know Your Rights Under the SCRA](/assets/downloads/FINRED-EndingLeaseSCRA-I.pdf)
The Servicemembers Civil Relief Act can help you break your housing lease early with military orders. Make sure you’re prepared – and protected – with this checklist.
* [Fair Credit Reporting Act: Consumer Protection for Borrowing Money](/assets/downloads/FINRED-FCRA-FS.pdf)
You are entitled to fair, accurate and private credit information. Take advantage of your consumer rights under the Fair Credit Reporting Act. 
* [Fair Debt Collection Practices Act: Consumer Protection for Borrowing Money](/assets/downloads/FINRED-FDCPA-FS.pdf)
Third-party collection agencies may contact you about outstanding debt. Learn your rights under the Fair Debt Collection Practices Act.   
* [How Marketing Affects Your Purchase Decisions](${root}/MarketingDecisions)
Learn how marketing strategies influence our spending habits while picking up shopping pointers that can enable you to reach your financial goals.
* [Keep Your Hard-Earned Money Safe](/assets/downloads/FINRED-I-MilConsumer-Protect.pdf)
As a service member, you have consumer credit protections and resources available to help protect your finances. Learn more in this infographic.
* [Learn Tips and Tricks for Managing Your Money](/assets/downloads/FINRED-I-MilConsumer-Tips.pdf)
Financial security and mission readiness go hand in hand. Use this infographic to learn tips and tricks for managing your money and staying mission-ready.
* [Military Lending Act](/assets/downloads/FINRED-MLA-FS.pdf)
There are regulations in place to protect you from predatory lenders. Review this fact sheet to understand your rights under the Military Lending Act.
* [Protect Your Finances During PCS With the Servicemembers Civil Relief Act](/assets/downloads/FINRED-SCRA-PCS-I.pdf)
Your PCS can impact your personal finances. See how one sailor and his spouse turned to the Servicemembers Civil Relief Act to protect theirs.
* [Servicemembers Civil Relief Act](/assets/downloads/FINRED-SCRA-FS.pdf)
The Servicemembers Civil Relief Act offers protections for active-duty service members and their families. Learn how the SCRA supports you from mortgages to life insurance.
* [Truth in Lending Act: Consumer Protection for Borrowing Money](/assets/downloads/FINRED-TruthLendingAct-FS.pdf)
Using credit and loans to buy a home, vehicle or other items can have a major impact on your financial security. Find out how the Truth in Lending Act can guide your borrowing decisions.

[Additional-Information]()
### Additional Information
Use these resources to learn more about consumer credit protections and your rights as a service or family member. Talk to a personal financial manager or personal financial counselor if you need additional information or assistance.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PFC-F.jpg"
            alt="Personal Financial Counselor flyer"
            title="It Pays to Visit a Personal Financial Counselor"
            href="/assets/downloads/FINRED-PFC-F.pdf"
            type="Flyer"
            link="Download"
          >
            <p>
            Find out how free, trusted personal support from personal financial counselors can help service members and their families make the most of their money.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Touchpoints-F.jpg"
            alt="Road to Financial Readiness flyer"
            title="Road to Financial Readiness "
            href="/StartHere/RoadToFinancialReadiness"
            type="Flyer"
            link="Download"
          >
            <p>
            Take the Road to Financial Readiness and see where it can lead you. Use this map to learn some of the key steps to take at each point in your career.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerProtections-R.jpg"
            alt="Credit card under twenty dollar bills"
            title="Understanding Consumer Protections Additional Resources"
            href={'/ToolsAndAddRes/AdditionalRes/Protections'}
            type="Collaborator Resources"
            link="Learn More"
          >
            <p>
            As a consumer, there are laws in place to protect you against scams and unfair practices. Learn more about your rights and how to stay safe with these resources.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
