/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/trendingPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';
import BaseTOC from '../components/tocs/baseTOC';


export default function FWBAResourcesServices (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    const toc = () => (
      <BaseTOC
        title="Financial Well-Being Assessment Service Resources"
        tocClass="trending"
        tocEntries={[
            { link: '#PRESENT-FINANCIAL-SECURITY', text: 'Present Financial Security' },
            { link: '#FUTURE-FINANCIAL-SECURITY', text: 'Future Financial Security' },
            { link: '#PRESENT-FREEDOM-OF-CHOICE', text: 'Present Freedom of Choice' },
            { link: '#FUTURE-FREEDOM-OF-CHOICE', text: 'Future Freedom of Choice' },
        ]}
      />
    );

    return (
      <Page
        crumbs={props.crumbs}
        toc={toc()}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Financial Well-Being Assessment Service Resources

Below, please find resources curated by your service, organized by the
same four financial areas covered in the Financial Well-Being
Assessment.

[PRESENT-FINANCIAL-SECURITY]()
#### PRESENT FINANCIAL SECURITY: *How well you manage your day-to-day and month-to-month finances*

**Army**

-   [Army Emergency Relief, or
    AER](https://www.armyemergencyrelief.org/assistance/)
-   Manage Your Debt and Pay Down Debt:

    -   [Roll-Down Your Credit Card Debt
        Calculator](https://www.financialfrontline.org/roll-down-your-credit-card-debt-calculator/)
    -   [Amortizing Loan
        Calculator](https://www.financialfrontline.org/amortizing-loan-calculator/)
    -   [Stream-Lined Snowball Debt Elimination
        Calculator](https://www.financialfrontline.org/stream-lined-snowball-debt-elimination-calculator/)

-   [Spending Plan
    Videos](https://www.financialfrontline.org/tools-and-resources/educational-videos/)
-   [Reserves Resources for Homeless and Food Resources By
    State](https://www.usar.army.mil/Featured/Family-Programs/Resources/)

**Marine Corps/Navy**

-   [Military OneSource: Military Leaders Economic Security
    Toolkit](https://www.militaryonesource.mil/leaders-service-providers/economic-security/)
-   [Debt Destroyer™
    Workshop](/SPL/ServiceResources/NavyResource/DebtDestroyerWorkshop)
-   [CFPB: Consumer Tools/Debt
    Collection](https://www.consumerfinance.gov/consumer-tools/debt-collection/)
-   [CFPB: Office of Servicemember
    Affairs](https://www.consumerfinance.gov/consumer-tools/educator-tools/servicemembers/)
-   [FINRED: Managing Your Money](/Money)
-   [CFPB: How to create a budget and stick with
    it](https://www.consumerfinance.gov/about-us/blog/budgeting-how-to-create-a-budget-and-stick-with-it/)
-   [MilSpouse Money Mission: Create a
    Budget](https://www.milspousemoneymission.org/create-a-budget/)

**Air Force/Space Force**

-   [Air Force Aid Society, or AFAS](https://afas.org/)
-   [Air Force Families Forever
    Program](https://www.afpc.af.mil/Airman-and-Family/Air-Force-Families-Forever/)
-   [CFPB: Consumer Tools/Debt
    Collection](https://www.consumerfinance.gov/consumer-tools/debt-collection/)
-   [CFPB: Office of Servicemember
    Affairs](https://www.consumerfinance.gov/consumer-tools/educator-tools/servicemembers/)

-   [FINRED: Managing Your Money](/Money)

[FUTURE-FINANCIAL-SECURITY]()
#### FUTURE FINANCIAL SECURITY: *Your capacity to absorb a financial shock*

**Army**

-   [Emergency Savings
    Calculator](https://www.financialfrontline.org/emergency-savings-calculator/)

-   Spouse Employment:

    -   [Should My Spouse
        Work? Calculator](https://www.financialfrontline.org/should-my-spouse-work/)
    -   [Employment Readiness
        Program](https://www.armyfamilywebportal.com/content/employment-readiness-program)

-   Estate Planning and Important Documents:

    -   [Plan Your
        Estate](https://www.milspousemoneymission.org/plan-your-estate/)
    -   [Estate Planning
        Handout](https://www.financialfrontline.org/wp-content/uploads/2019/11/Army-FRP-Handout-Estate-Planning_Final-2023.pdf)

**Marine Corps/Navy**

-   [CFPB: An essential guide to building an emergency
    fund](https://www.consumerfinance.gov/an-essential-guide-to-building-an-emergency-fund/)
-   [CFPB: How to save for emergencies and the
    future](https://www.consumerfinance.gov/about-us/blog/how-save-emergencies-and-future/)
-   [MilSpouse Money Mission: Pay Yourself
    First](https://www.milspousemoneymission.org/save-money/)
-   [Marine Corps Community Services: Personal Financial
    Management](https://www.usmc-mccs.org/services/career/personal-financial-management/)
-   [MilSpouse Money Mission: Financial Preparation for
    Deployment](https://www.milspousemoneymission.org/military-spouse-tip/financial-preparation-for-deployment/)
-   [MilSpouse Money Mission:
    Pre-Deployment](https://www.milspousemoneymission.org/military-lifecycle-pre-deployment-touchpoint/)

**Air Force/Space Force**

-   [MilSpouse Money Mission: Plan Your
    Estate](https://www.milspousemoneymission.org/plan-your-estate/)
-   [CFPB: An essential guide to building an emergency
    fund](https://www.consumerfinance.gov/an-essential-guide-to-building-an-emergency-fund/)
-   [CFPB: How to save for emergencies and the
    future](https://www.consumerfinance.gov/about-us/blog/how-save-emergencies-and-future/)
-   [MilSpouse Money Mission: Financial Preparation for
    Deployment](https://www.milspousemoneymission.org/military-spouse-tip/financial-preparation-for-deployment/)
-   [MilSpouse Money Mission:
    Pre-Deployment](https://www.milspousemoneymission.org/military-lifecycle-pre-deployment-touchpoint/)
-   [Financial Readiness Training for
    Spouses](/SPL/ServiceResources/AirForceResource)

[PRESENT-FREEDOM-OF-CHOICE]()
#### PRESENT FREEDOM OF CHOICE: *Your freedom to make financial choices without regret*

**Army**

-   [Spending Plan
    Worksheet](https://www.financialfrontline.org/wp-content/uploads/2019/11/Army-Spending-Plan-Worksheet-New-8.pdf)
-   [Investment Goal
    Calculator](https://www.financialfrontline.org/investment-goal-calculator/)

-   Credit Report and Score:

    -   [Financial Frontline:
        Families](https://www.financialfrontline.org/families/)
    -   [MilSpouse Money Mission: Understand Credit
        Videos](https://www.milspousemoneymission.org/money-ready-101-videos/#credit)

**Marine Corps/Navy**

-   [MilSpouse Money Mission: Major
    Purchases](https://www.milspousemoneymission.org/major-purchases/)
-   [MilSpouse Money Mission: Plan to
    Retire](https://www.milspousemoneymission.org/plan-to-retire/)
-   [TSP.gov](https://www.tsp.gov/)
-   [Investor.gov](https://www.investor.gov/)
-   [AnnualCreditReport.com](https://www.annualcreditreport.com/index.action)
-   [CFPB: Credit reports and
    scores](https://www.consumerfinance.gov/consumer-tools/credit-reports-and-scores/)
-   [CFPB: Where can I get my credit
    score?](https://www.consumerfinance.gov/ask-cfpb/where-can-i-get-my-credit-score-en-316/)
-   [CFPB: What is the difference between a credit report and credit
    score?](https://www.consumerfinance.gov/ask-cfpb/what-is-the-difference-between-a-credit-report-and-a-credit-score-en-2069/)
-   [CFPB: How do I dispute an error on my credit
    report?](https://www.consumerfinance.gov/ask-cfpb/how-do-i-dispute-an-error-on-my-credit-report-en-314/)

**Air Force/Space Force**

-   [AnnualCreditReport.com](https://www.annualcreditreport.com/index.action)
-   [MilSpouse Money Mission: Understand Credit
    Videos](https://www.milspousemoneymission.org/money-ready-101-videos/#credit)
-   [TSP.gov](https://www.tsp.gov/)
-   [CFPB: Where can I get my credit
    score?](https://www.consumerfinance.gov/ask-cfpb/where-can-i-get-my-credit-score-en-316/)
-   [CFPB: What is the difference between a credit report and credit
    score?](https://www.consumerfinance.gov/ask-cfpb/what-is-the-difference-between-a-credit-report-and-a-credit-score-en-2069/)
-   [CFPB: How do I dispute an error on my credit
    report?](https://www.consumerfinance.gov/ask-cfpb/how-do-i-dispute-an-error-on-my-credit-report-en-314/)

[FUTURE-FREEDOM-OF-CHOICE]()
#### FUTURE FREEDOM OF CHOICE: *Your efforts toward future financial success*

**Army**

-   [Self-Assessment
    Tool](https://www.financialfrontline.org/self-assessment-tool/)
-   Retirement Calculators:

    -   [401(k)
        Calculator](https://www.financialfrontline.org/401k-calculator/)
    -   [Retirement Planner
        Calculator](https://www.financialfrontline.org/retirement-planner-calculator/)

-   [Spending Plan
    Worksheet](https://www.financialfrontline.org/wp-content/uploads/2019/11/Army-Spending-Plan-Worksheet-New-8.pdf)

**Marine Corps/Navy**

-   [CFPB: Your Money, Your Goals
    toolkit](https://www.consumerfinance.gov/consumer-tools/educator-tools/your-money-your-goals/toolkit/)
-   [CFPB:
    Booklets](https://www.consumerfinance.gov/consumer-tools/educator-tools/your-money-your-goals/booklets-talk-about-money/)
-   Thrift Savings Plan:

    -   [TSP basics](https://www.tsp.gov/tsp-basics/)
    -   [How to invest](https://www.tsp.gov/how-to-invest/)
    -   [New TSP features](https://www.tsp.gov/new-tsp-features/)

-   SEC Investor.gov:

    -   [Introduction to
        Investing](https://www.investor.gov/introduction-investing)
    -   [Using EDGAR To Research
        Investments](https://www.investor.gov/introduction-investing/getting-started/researching-investments/using-edgar-research-investments)
    -   [Savings Goal
        Calculator](https://www.investor.gov/financial-tools-calculators/calculators/savings-goal-calculator)

-   [FINRED: Saving and
    Investing](/Saving)
-   [FINRED: Planning for the
    Future](/Planning)
-   [Marine Corps Community Services: Personal Financial
    Management](https://www.usmc-mccs.org/services/career/personal-financial-management/)

**Air Force/Space Force**

-   [Investing
    Calculators](/ToolsAndAddRes/Calculators/Investing)
-   [Retirement
    Calculators](/ToolsAndAddRes/Calculators/Retirement)
-   [CFPB: Your Money, Your Goals
    toolkit](https://www.consumerfinance.gov/consumer-tools/educator-tools/your-money-your-goals/toolkit/)
-   [CFPB:
    Booklets](https://www.consumerfinance.gov/consumer-tools/educator-tools/your-money-your-goals/booklets-talk-about-money/)

-   Thrift Savings Plan:

    -   [TSP basics](https://www.tsp.gov/tsp-basics/)
    -   [How to invest](https://www.tsp.gov/how-to-invest/)
    -   [New TSP features](https://www.tsp.gov/new-tsp-features/)          
            `}

          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}
