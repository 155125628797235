/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import HeroSlide from './heroSlide';

export default function HeroCarousel({ setSite }) {
    return (
      <div className="hero-container">
        <CarouselProvider
          className="hero-content-box"
          infinite
          isIntrinsicHeight
          totalSlides={3}
          interval={5000}
          isPlaying
        >
          <Slider>
            <Slide index={0}>
              <HeroSlide
                title="Understand Your Options"
                img="/assets/img/FINRED-WebsiteImage-Oct2024-H-RetirementDDP.jpg"
                imgAlt="BRS logo, icons for money, Thrift Savings Plan"
                buttonLink="/Benefits/Retirement/RetirementFoundations"
                buttonText="Start Planning"
                // buttonCentered
              >
                <p>
                Optimize your retirement benefits. Learn what you can do now to prepare for a comfortable future.
                </p>
              </HeroSlide>
            </Slide>


            <Slide index={1}>
              <HeroSlide
                // setSite={setSite}
                title="Why Do You Need a Will?"
                img="/assets/img/FINRED-WebsiteImage-Oct2024-H-Will.jpg"
                imgAlt="Female service member holding baby"
                buttonLink="/Planning/ImportanceofHavingaWill"
                buttonText="Read Now"
              >
                <p>
                Learn why a will is important and pick up tips on how to create one that will protect your loved ones.
                </p>
              </HeroSlide>
            </Slide>

            <Slide index={2}>
              <HeroSlide
                // setSite={setSite}
                title="10 Ways To Use DCFSA Funds"
                img="/assets/img/FINRED-WebsiteImage-Oct2024-H-DCFSA.jpg"
                imgAlt="Woman holding child on lap near another woman"
                buttonLink="/assets/downloads/FINRED-10WaysDCFSA-I.pdf"
                buttonText="Get the Flyer"
              >
                <p>
                Uncover the surprising ways you can use DCFSA funds to take care of loved ones.
                </p>
              </HeroSlide>
            </Slide>

            {/* <Slide index={3}>
              <HeroSlide
                setSite={setSite}
                title="Join Us on LinkedIn!"
                img="/assets/img/FINRED-WebsiteImage-February2024-HomepageHero-LinkedIn.jpg"
                imgAlt="LinkedIn logo"
                buttonLink="http://linkedin.com/company/dodfinred"
                buttonText="Get Connected"
                // buttonCentered
              >
                <p>
                Follow the DoD Office of Financial Readiness for fresh financial tips and news.
                </p>
              </HeroSlide>
            </Slide> */}
          </Slider>
          <ButtonBack className="move-button left"><span className="fa fa-angle-left" /></ButtonBack>
          <ButtonNext className="move-button right"><span className="fa fa-angle-right" /></ButtonNext>
          <DotGroup className="hero-dots" />
        </CarouselProvider>
      </div>
    );
}
